import * as React from 'react';

interface CloudFrontImageProps {
  img: string;
  size: string[];
  alt?: string;
  fit?: boolean;
  smart?: boolean;
  quality?: number;
}

const { REACT_APP_CLOUD_FRONT_ROOT } = process.env;

// docs for resize methods located at https://thumbor.readthedocs.io/en/latest/usage.html
// supports srcSet with the size array
// the first in the size array is 1x second is 2x etc...
// ie size={[100x100, 200x200, 400x400]}

export const CloudFrontImage = (props: CloudFrontImageProps) => {
  const { size, fit, img, alt, smart, quality } = props;
  const fitStr = fit ? 'fit-in/' : '';
  const smartStr = smart ? 'smart/' : '';
  const qualityStr = quality ? `filters:quality(${quality})/` : '';
  const rawSrc = `${REACT_APP_CLOUD_FRONT_ROOT}/${fitStr}:size:/${smartStr}${qualityStr}${img}`;
  const srcSet = size.map((s, i) => rawSrc.replace(':size:', s) + ` ${i + 1}x`);
  return (
    <img
      alt={alt || ''}
      srcSet={srcSet.length > 1 ? srcSet.join(',') : undefined}
      className="CloudFrontImage"
    />
  );
};
