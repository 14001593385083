import React from 'react';
import { SiteProps } from '../../types';
import axios, { AxiosError } from 'axios';
import { AptoSpinner } from '@apto/aptotude';
import './staticMap.scss';

interface Property {
  site: SiteProps;
}

interface State {
  mapUrls: MapUrls;
  mapApiUrl: {
    base: string;
    normalSize: string;
    doubleDensitySize: string;
  };
  loading: LoadingState;
}

enum LoadingState {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error'
}

interface MapUrls {
  normalSize: string;
  doubleDensitySize: string;
}

export class StaticMap extends React.Component<Property, State> {
  public state = {
    mapApiUrl: {
      base:
        `${process.env.MAPPING_URL}/api/static-map?type=2&zoom=13&size=1265x300`,
      normalSize: 'scale=1',
      doubleDensitySize: 'scale=2'
    },

    mapUrls: {
      normalSize: '',
      doubleDensitySize: ''
    },

    loading: LoadingState.loading
  };

  private _isMounted = false;

  get isMounted(): boolean {
    return this._isMounted;
  }

  set isMounted(mountedStatus: boolean) {
    this._isMounted = mountedStatus;
  }

  public componentWillMount() {
    try {
      this.isMounted = true;

      const mapCenter = this._calculateMapCenter(this.props.site);

      const baseUrl = `${
        this.state.mapApiUrl.base
      }&center=${mapCenter}&markers=${mapCenter}`;

      const options = {
        headers: { 'allow-origin': this.state.mapApiUrl.base }
      };

      Promise.all([
        axios.get(`${baseUrl}&${this.state.mapApiUrl.normalSize}`, options),
        axios.get(`${baseUrl}&${this.state.mapApiUrl.doubleDensitySize}`, options)
      ])
        .then(this._normalizeApiUrls)
        .then(mapUrls => {
          if (this.isMounted) {
            this.setState({ mapUrls, loading: LoadingState.loaded });
          }
        })
        .catch(this._handleGetUrlsError);
    } catch (error) {
      if (this.isMounted) {
        this.setState({ loading: LoadingState.error });
      }
    }
  }

  public componentWillUnmount() {
    this.isMounted = false;
  }

  private _calculateMapCenter({
    latitude,
    longitude,
    city,
    state,
    street_address,
    country
  }: SiteProps): string {
    let mapCenter;

    if (latitude && longitude) {
      mapCenter = `${latitude},${longitude}`;
    } else if (city || state || street_address) {
      const addressData = country
        ? [street_address, city, state, country]
        : [street_address, city, state];

      mapCenter = addressData.reduce(
        (mapString, address) => (address ? `${mapString} ${address}` : ``),
        ''
      );
    }

    if (mapCenter) {
      return mapCenter;
    } else {
      throw new Error('no map data provided');
    }
  }

  private _normalizeApiUrls(urlsFromApi: any): MapUrls {
    return {
      normalSize: urlsFromApi[0].data,
      doubleDensitySize: urlsFromApi[1].data
    };
  }

  private _handleGetUrlsError = (error: AxiosError[]) => {
    if (this.isMounted) {
      this.setState({ loading: LoadingState.error });
    }
  };

  public render() {
    if (this.state.loading === LoadingState.loading) {
      return <AptoSpinner />;
    } else if (this.state.loading === LoadingState.loaded) {
      return (
        <img
          alt="top down map of the site"
          src={this.state.mapUrls.normalSize}
          srcSet={`${this.state.mapUrls.doubleDensitySize} 2x`}
          className="StaticMap"
        />
      );
    } else {
      return null;
    }
  }
}
